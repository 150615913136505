<template>
    <div
        class="flex flex-col sm:pt-60 pt-48 w-full overflow-x-hidden"
        @click="close"
    >
        <div
            class="flex flex-col-reverse sm:flex-row-reverse items-center justify-between sm:justify-between flex-col pl-0 pr-0"
        >
            <div
                class="flex flex-row justify-center mt-12 ml-8 mr-8 sm:ml-64 sm:mr-60"
            >
                <img class="img1 w-72" src="@/assets/images/labs1.svg" />
                <img
                    class="img2 sm:w-88 sm:pt-96 pt-0 pl-0"
                    src="@/assets/images/labs2.svg"
                />
                <img
                    class="img3 w-88 sm:pb-32"
                    src="@/assets/images/labs3.svg"
                />
            </div>
            <div
                class="flex flex-col ml-8 mr-8 sm:w-auto sm:pl-60 pr-0 sm:pt-24 sm:pt-0"
            >
                <div class="text-3xl font-extrabold">What is SDSLabs</div>
                <div
                    class="sm:w-180 pt-10 text-1.5xl sm:text-lg sm:font-medium text-grey leading-170"
                >
                    SDSLabs or Software Development Section Laboratories is a
                    student-run technical group at IIT Roorkee, which aims to
                    encourage the development of technology and innovation in
                    the campus and beyond. We believe in learning and sharing
                    knowledge; collaboration and experimentation; and human
                    interaction that matters the most.
                </div>
            </div>
        </div>
        <div
            class="flex flex-col-reverse mt-36 sm:flex-row sm:justify-between sm:ml-64 sm:mr-36 sm:pt-36"
        >
            <div class="mt-16 sm:mt-0 ml-8 mr-8"><Gallery /></div>
            <div class="flex flex-col mr-8 ml-8 sm:w-auto sm:ml-100 sm:pt-8">
                <div class="text-3xl font-extrabold">What we do</div>
                <div
                    class="sm:w-140 pt-10 text-1.5xl sm:text-lg sm:font-medium text-grey leading-170"
                >
                    We are involved in a lot of activities on the campus, off
                    the campus and on the web. We conduct lectures and workshops
                    on different fields of tech, organize hackathons, and also
                    conduct online competitions on our various self developed
                    applications.
                </div>
            </div>
        </div>
        <div class="flex flex-col mt-32 sm:mt-100 w-full">
            <div class="area_of_interest">
                <div
                    class="flex flex-col-reverse sm:flex-row-reverse items-center sm:justify-between"
                >
                    <div
                        class="sm:mr-28 sm:w-220 w-full mt-16 sm:flex sm:flex-row"
                    >
                        <div>
                            <div
                                class="flex rounded shadow-sister flex-row ml-8 mr-8 h-auto sm:w-92"
                            >
                                <div
                                    class="my-8 mx-10 w-20 h-20 sm:m-8 sm:w-auto sm:h-auto"
                                >
                                    <img src="@/assets/images/interest1.png" />
                                </div>
                                <div
                                    class="font-sans font-semibold self-center text-1.5xl leading-280 sm:text-base align-middle sm:leading-160"
                                >
                                    Software Development
                                </div>
                            </div>
                            <div class="mt-8">
                                <div
                                    class="flex rounded shadow-sister mt-0 mr-0 flex-row ml-8 mr-8 sm:w-92 h-auto"
                                >
                                    <div
                                        class="my-8 mx-10 w-20 h-20 sm:m-8 sm:w-auto sm:h-auto"
                                    >
                                        <img
                                            src="@/assets/images/interest3.png"
                                        />
                                    </div>
                                    <div
                                        class="font-sans font-semibold self-center text-1.5xl leading-280 sm:text-base align-middle sm:leading-160"
                                    >
                                        Product Design
                                    </div>
                                </div>
                            </div>
                            <div class="mt-8">
                                <div
                                    class="flex rounded shadow-sister mt-0 mr-0 flex-row ml-8 mr-8 sm:w-92 h-auto"
                                >
                                    <div
                                        class="my-8 mx-10 w-20 h-20 sm:m-8 sm:w-auto sm:h-auto"
                                    >
                                        <img
                                            src="@/assets/images/interest5.png"
                                        />
                                    </div>
                                    <div
                                        class="font-sans font-semibold self-center text-1.5xl leading-280 sm:text-base align-middle sm:leading-160 sm:mr-10"
                                    >
                                        Game Development
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class=" sm:-ml-8 sm:mt-12">
                            <div class="mt-8">
                                <div
                                    class="flex rounded shadow-sister mt-0 flex-row ml-8 mr-8 sm:w-92 h-auto"
                                >
                                    <div
                                        class="my-8 mx-10 w-20 h-20 sm:m-8 sm:w-auto sm:h-auto"
                                    >
                                        <img
                                            src="@/assets/images/interest2.png"
                                        />
                                    </div>
                                    <div
                                        class="font-sans font-semibold self-center text-1.5xl leading-280 sm:text-base align-middle sm:leading-160 sm:mr-10"
                                    >
                                        Information Security
                                    </div>
                                </div>
                            </div>
                            <div class="mt-8">
                                <div
                                    class="flex rounded shadow-sister mt-0 mr-0 flex-row ml-8 mr-8 sm:w-92 h-auto"
                                >
                                    <div
                                        class="my-8 mx-10 w-20 h-20 sm:m-8 sm:w-auto sm:h-auto"
                                    >
                                        <img
                                            src="@/assets/images/interest4.png"
                                        />
                                    </div>
                                    <div
                                        class="font-sans font-semibold self-center text-1.5xl leading-280 sm:text-base align-middle sm:leading-160"
                                    >
                                        Data Science
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col sm:pl-60 mx-10">
                        <div class="text-3xl font-extrabold">
                            Areas of Interest
                        </div>
                        <div
                            class="sm:w-180 pt-10 text-1.5xl sm:text-lg sm:font-medium text-grey leading-170"
                        >
                            We constantly explore and experiment, try to learn
                            new things, and get out of the comfort zones. This
                            gives the members of SDSLabs a freedom to work in a
                            diverse range of fields in technology and
                            innovation, some of which are mentioned here.
                        </div>
                    </div>
                </div>
            </div>

            <div class="timeline flex flex-col sm:items-center sm:mt-92 mt-36">
                <div
                    class="flex sm:flex-col sm:items-center sm:justify-between flex-col mx-10 sm:ml-0"
                >
                    <div class="text-3xl font-black">Timeline</div>
                    <div
                        class="sm:w-292 pt-10 text-1.5xl sm:text-lg sm:font-medium text-grey leading-170 w-auto sm:text-center"
                    >
                        The timeline shows our past achievements that we’re
                        proud of and the milestones that we were able to achieve
                        as a group over the course of time.
                    </div>
                </div>

                <div
                    id="timeline_bar"
                    class="timeline_bar flex align-bottom h-auto sm:p-8 sm:mt-8 w-full mt-16 overflow-x-auto sm:pl-64"
                >
                    <div
                        class="timeline-bar flex flex-row-reverse sm:flex-no-wrap py-10 sm:py-1"
                        v-for="(timeline, index) in timelines"
                        v-bind:key="index"
                    >
                        <div
                            class="timeline-box shadow-sister ml-5 mr-5 self-center w-148 sm:w-144 p-10"
                        >
                            <div
                                :class="
                                    'text-xl sm:text-base font-semibold text-' +
                                        colors[index.length % 6]
                                "
                            >
                                {{ timeline.timing }}
                            </div>
                            <div
                                class="text-1.5xl sm:text-lg text-grey-900 font-semibold pt-14"
                            >
                                {{ timeline.title }}
                            </div>
                            <div
                                class="text-xl sm:text-base text-grey pt-10 leading-normal"
                            >
                                {{ timeline.description }}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="flex flex-row">
                    <div class="scroll_icon w-auto hidden sm:block">
                        <button
                            id="scroll_btn"
                            class="scroll_btn self-center h-10 sm:w-16 sm:h-16 mt-16 sm:mt-16"
                            v-on:click="scrollLeftFunction"
                        >
                            <img class="transform rotate-180" src="@/assets/images/scroll-arrow-left.svg" />
                        </button>
                    </div>
                    <div class="scroll_icon w-auto hidden sm:block">
                        <button
                            id="scroll_btn"
                            class="scroll_btn self-center h-10 sm:w-16 sm:h-16 mt-16 sm:mt-16"
                            v-on:click="scrollRightFunction"
                        >
                            <img src="@/assets/images/scroll-arrow-right.svg" />
                        </button>
                    </div>
                </div>
            </div>
            <div><Team /></div>
            <div class="pt-24 sm:mt-24 pb-44"><SisterGroups /></div>
        </div>
    </div>
</template>

<script>
import Gallery from "@/components/Gallery.vue";
import Team from "@/components/Team.vue";
import SisterGroups from "@/components/SisterGroups.vue";
import { CONFIG } from "@/utils/constants.js";
import axios from "axios";
import { CLOSE_USER } from "@/mutation-types";

export default {
    name: "about",
    components: {
        Gallery,
        Team,
        SisterGroups
    },
    data: function initData() {
        return {
            timelines: {},
            colors: CONFIG.colors
        };
    },
    methods: {
        scrollRightFunction: function() {
            document.getElementById("timeline_bar").scrollLeft += 1000;
        },
        scrollLeftFunction: function() {
            document.getElementById("timeline_bar").scrollLeft -= 1000;
        },
        close() {
            this.$store.commit(CLOSE_USER);
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        axios
            .get(`${CONFIG.baseURL}/api/timeline/?format=json`)
            .then(response => {
                let timelines = {};
                for (let i = 0; i < response.data.length; i++) {
                    timelines[response.data[i]["title"]] = response.data[i];
                }
                this.timelines = Object.assign({}, this.timelines, timelines);
            });
    }
};
</script>

<style lang="scss" scoped>
.timeline_bar {
    scroll-behavior: smooth;
}

.scroll_btn:focus {
    border: none;
    outline: none;
}

.timeline_bar::-webkit-scrollbar {
    display: none;
}

.timeline_bar {
    scrollbar-width: none;
}

.timeline-bar:nth-child(odd) {
    width: 36rem;
}

.timeline-bar:nth-child(even) {
    width: 26.4rem;
}

@media screen and (max-width: 576px) {
    .img1 {
        width: 12.78rem;
        height: 10.326rem;
        margin-top: 3.3rem;
    }

    .img2 {
        width: 13.18rem;
        height: 8.92rem;
        margin-top: 11.5rem;
        margin-left: 0.7rem;
    }

    .img3 {
        width: 14.213rem;
        height: 8.99rem;
    }
}
</style>
