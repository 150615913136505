var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.key,staticClass:"flex flex-col ml-10 mr-10 sm:ml-0 sm:mr-0 pt-44 sm:pt-60 sm:pl-60 sm:w-full",on:{"click":_vm.close}},[_vm._m(0),_c('div',{staticClass:"grid sm:w-news pt-24 pb-24",attrs:{"id":"grid"}},_vm._l((_vm.event),function(event_block){return _c('div',{key:event_block.event.title,staticClass:"grid-item sm:pr-navbar sm:w-event"},[(
                    event_block.event.types == 'ongoing event' &&
                        event_block.event.priority == 'large'
                )?_c('div',{staticClass:"mb-10 sm:w-feed",attrs:{"id":"feed"}},[_c('LargeFeed',{attrs:{"event":event_block.event,"eventUpdates":event_block.event_update}})],1):_vm._e(),(
                    event_block.event.types == 'ongoing event' &&
                        event_block.event.priority == 'small'
                )?_c('div',[_c('SmallFeed',{staticClass:"mb-10 sm:w-event",attrs:{"event":event_block.event}})],1):_vm._e(),(
                    event_block.event.types == 'upcoming event' &&
                        event_block.event.priority == 'large'
                )?_c('div',{staticClass:"mb-10 sm:w-feed",attrs:{"id":"feed"}},[_c('LargeFeed',{attrs:{"event":event_block.event,"eventUpdates":event_block.event_update}})],1):_vm._e(),(
                    event_block.event.types == 'upcoming event' &&
                        event_block.event.priority == 'small'
                )?_c('div',[_c('SmallFeed',{staticClass:"mb-10 sm:w-event",attrs:{"event":event_block.event}})],1):_vm._e(),(
                    event_block.event.types == 'online competition' &&
                        event_block.event.priority == 'large'
                )?_c('div',{attrs:{"id":"feed"}},[_c('LargeFeed',{attrs:{"event":event_block.event,"eventUpdates":event_block.event_update}})],1):_vm._e(),(
                    event_block.event.types == 'online competition' &&
                        event_block.event.priority == 'small'
                )?_c('div',{staticClass:"mb-10 sm:w-event"},[_c('SmallFeed',{attrs:{"event":event_block.event}})],1):_vm._e(),(
                    event_block.event.types == 'app update' &&
                        event_block.event.priority == 'large'
                )?_c('div',{attrs:{"id":"feed"}},[_c('LargeFeed',{attrs:{"event":event_block.event,"eventUpdates":event_block.event_update}})],1):_vm._e(),(
                    event_block.event.types == 'app update' &&
                        event_block.event.priority == 'small'
                )?_c('div',{staticClass:"mb-10 sm:w-event"},[_c('SmallFeed',{attrs:{"event":event_block.event}})],1):_vm._e(),(
                    event_block.event.types == 'past event' &&
                        event_block.event.priority == 'large'
                )?_c('div',{staticClass:"mb-10 sm:w-feed",attrs:{"id":"feed"}},[_c('LargeFeed',{attrs:{"event":event_block.event,"eventUpdates":event_block.event_update}})],1):_vm._e(),(
                    event_block.event.types == 'past event' &&
                        event_block.event.priority == 'small'
                )?_c('div',{staticClass:"mb-10 sm:w-feed"},[_c('SmallFeed',{attrs:{"event":event_block.event}})],1):_vm._e()])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"font-black text-3xl leading-180"},[_vm._v("News Updates")]),_c('div',{staticClass:"sm:w-largetext sm:text-base mt-10 sm:leading-normal text-1.5xl text-grey leading-170"},[_vm._v("\n            Stay updated on coming up events or know more about things from\n            recent past; things of your interest from our side.\n        ")])])}]

export { render, staticRenderFns }