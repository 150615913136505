var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"gallery hidden sm:flex sm:flex-col w-auto sm:w-gallery"},[_c('img',{key:_vm.queue,staticClass:"image sm:h-128 sm:w-180 rounded-lg",attrs:{"src":_vm.slides[_vm.queue - 1].image,"alt":"image"}}),_c('div',{key:_vm.queue,staticClass:"textbox w-auto ml-6 mr-6 h-auto min-h-88 -mt-32 pr-6 sm:w-120 sm:h-auto sm:min-h-88 sm:ml-98 sm:-mt-gallery bg-white z-20"},[_c('div',{staticClass:"font-extrabold text-2xl sm:text-lg mt-10 ml-10"},[_vm._v("\n                "+_vm._s(_vm.slides[_vm.queue - 1].title)+"\n            ")]),_c('div',{staticClass:"sm:w-104 leading-170 text-grey text-xl sm:text-base mt-8 ml-10 mb-8"},[_vm._v("\n                "+_vm._s(_vm.slides[_vm.queue - 1].description)+"\n            ")])]),_c('div',{staticClass:"self-center sm:self-start sm:-mt-32"},[_c('span',{staticClass:"h-4 w-4 sm:h-3 sm:w-3 rounded-50 inline-block mt-16 pointer",class:{
                    'bg-black': _vm.queue === 1,
                    'bg-carousel': _vm.queue !== 1
                },on:{"click":function($event){_vm.queue = 1}}}),_c('span',{staticClass:"h-4 w-4 sm:h-3 sm:w-3 rounded-50 inline-block mt-16 ml-4 pointer",class:{
                    'bg-black': _vm.queue === 2,
                    'bg-carousel': _vm.queue !== 2
                },on:{"click":function($event){_vm.queue = 2}}}),_c('span',{staticClass:"h-4 w-4 sm:h-3 sm:w-3 rounded-50 inline-block mt-16 ml-4 pointer",class:{
                    'bg-black': _vm.queue === 3,
                    'bg-carousel': _vm.queue !== 3
                },on:{"click":function($event){_vm.queue = 3}}}),_c('span',{staticClass:"h-4 w-4 sm:h-3 sm:w-3 rounded-50 inline-block mt-16 ml-4 pointer",class:{
                    'bg-black': _vm.queue === 4,
                    'bg-carousel': _vm.queue !== 4
                },on:{"click":function($event){_vm.queue = 4}}})])]),_c('div',{staticClass:"sm:hidden"},[_c('GalleryMobile')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }